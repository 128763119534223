import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "./NavBar";
import Footer from "./Footer";
import HomePage from "../pages/HomePage";
import NotFound from "../pages/NotFound";
import AttentionToCompanies from "../pages/AttentionToCompanies";
import PersonalShipments from "../pages/PersonalShipments";
import Coverage from "../pages/Coverage";
import Contact from "../pages/Contact";
import ParcelDelivery from "../pages/ParcelDelivery";
import PrivacyPolicies from "./PrivacyPolicies";
import ShipmentTracking from "../pages/ShipmentTracking";
import Quotation from "../pages/Quotation";
import UnavailablePage from "../pages/UnavailablePage";

export default function App() {

    return (
        <BrowserRouter>

            {/*<NavBar /> */}

            <Routes>
                {/* 
                <Route path="/" element={<HomePage />}></Route>
                <Route path="/servicio-de-paqueteria" element={<AttentionToCompanies />}></Route>
                <Route path="/mensajeria-y-paqueteria" element={<PersonalShipments />}></Route>*/}

                {/* 
                <Route path="/facturacion-paqueteria-yavoy" element={<Billing />}></Route>
                <Route path="/paqueteria-para-refaccionarias" element={<RepairShops />}></Route>
                <Route path="/paqueteria-para-industria-farmaceutica" element={<PharmaceuticalIndustry />}></Route>
                */}

                {/* 
                <Route path="/rastreo-de-envio" element={<ShipmentTracking />}/>
                <Route path="/cotizar-envio" element={<Quotation />}/>
                <Route path="/cobertura-de-paqueteria" element={<Coverage />}></Route>
                <Route path="/empresa-de-paqueteria" element={<Contact />}></Route>
                <Route path="/envio-paqueteria" element={<ParcelDelivery />}></Route>
                <Route path="/politicas" element={<PrivacyPolicies />}></Route>
                */}

                <Route path="*" element={<NotFound />}></Route>
                <Route path="/aviso" element={<UnavailablePage />}></Route>
            </Routes>
            
            {/*<Footer /> */}

            <script src="/assets/js/jquery.min.js"></script>

        </BrowserRouter>
    );
}